export class FiltroConsolidado {
  radicado?: number;
  inmueble?: string;
  nombreCliente?: string;
  estado?: number;
  encargado?: string;
  fecha?: string;
  nombreProyecto?: string;
  plantillas?: number;
  fuente?: number;
  prioridad?: number;
  usuario?: string;
  tipoUsuario?: number;
  locativa?: number;
  subservicio?: number;
  idCategoria?: number;
}

export class FiltroPlantillas {
  nombrePlantilla?: string;
}

export class FiltroServicio {
  idCategoria?: number;
  servicio?: number;
  ciudad?: number;
  progresoInicio?: number;
  progresoFinal?: number;
  prioridad?: number;
  inmueble?: string;
  direccion?: string;
  nombreCliente?: string;
  estado?: number;
  responsable?: string;
  fechaInicio?: string;
  fechaFinal?: string;
  nombreProyecto?: string;
  creadoPor?: number;
  fuente?: number;
  usuario?: string;
  tipoUsuario?: number;
  fechaInicioCreacion?: string;
  fechaFinalCreacion?: string;
  provider?: string;
  subservicio?: number;
  origenPQR: number;
  tipoPQR: number;

  // tslint:disable-next-line: variable-name
  radicado_simi?: string;
  radicado?: string;
  proveedor?: string;
  categoria?: string;
  subCategoria?: string;
  excel?: number;
  fechaInicioSolicitud?: string;
  fechaFinalSolicitud?: string;
  idProceso?: number;
  tipoFiltro?: string;
  nombreProceso?: number;
  quienCreoProceso?: number;
}

